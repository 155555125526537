import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes = 0, precision = 1): string {
    const isNaN = Number.isNaN(parseFloat(bytes.toString()));
    if (isNaN || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;
    const kilobyte = 1024;
    while (bytes >= kilobyte) {
      bytes /= kilobyte;
      unit++;
    }

    return `${bytes.toFixed(+precision)} ${this.units[unit]}`;
  }
}
