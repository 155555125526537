import { ModuleWithProviders, NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { LocalDatePipe } from './local-date.pipe';
import { ProposalStatusClassPipe } from './proposal-status-class.pipe';
import { FileSizePipe } from './file-size.pipe';
import { StringClassPipe } from './string-class.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { GuidPipe } from './guid.pipe';
import { SumPipe } from './sum.pipe';
import { FirstCharUpperPipe } from './first-char-upper';
import { NotPipe } from './not.pipe';

@NgModule({
  imports: [
    LocalDatePipe,
    ProposalStatusClassPipe,
    StringClassPipe,
    FileSizePipe,
    CurrencyFormatPipe,
    GuidPipe,
    SumPipe,
    FirstCharUpperPipe,
    NotPipe,
  ],
  exports: [
    LocalDatePipe,
    ProposalStatusClassPipe,
    StringClassPipe,
    FileSizePipe,
    CurrencyFormatPipe,
    GuidPipe,
    SumPipe,
    FirstCharUpperPipe,
    NotPipe,
  ],
})
export class PipeModule {
  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
      ngModule: PipeModule,
      providers: [
        DecimalPipe,
        LocalDatePipe,
        ProposalStatusClassPipe,
        StringClassPipe,
        FileSizePipe,
        CurrencyFormatPipe,
        SumPipe,
      ],
    };
  }
}
