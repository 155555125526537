import { Component } from '@angular/core';

import { AuthenticationService } from '../auth/authentication.service';
import { MainContentComponent } from '../layout/main-content/main-content.component';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [MainContentComponent],
})
export class NotFoundComponent {
  constructor(public auth: AuthenticationService) {}
}
